import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { PuzzlesqueryService } from '../services/puzzles/puzzlesquery.service';
import { SkillkitService } from '../services/skillkit/skillkit.service';
import {
  profileget, themeUpdate, audioUpdate, langUpdate, getThemeScore,
  newpwd_SA_session, updatebasetheme, schChange, nameChange
} from '../services/profile/profile'
import { FormBuilder, FormGroup } from '@angular/forms';
import { IpService } from '../services/ip/ip.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-language-selection',
  templateUrl: './language-selection.component.html',
  styleUrls: ['./language-selection.component.scss']
})
export class LanguageSelectionComponent implements OnInit {
    //////theme variables//////
    imageUrl_color = '/assets/images/colorbar.png';
    updatebasetheme = new updatebasetheme();
    colortheme = ['black', 'white'];
    colortheme_shad = ['white', '#ffffff36'];
    colortheme_shad_val;
    colortheme_bg;
    colortheme_txt;
    language:FormGroup;
    colortheme_base = ['#0D0F12', 'white']
    colortheme_bg_base;
    colortheme_base_sec = ['#181C20', '#F0F0F0','#FFFFFF']
    colortheme_bg_base_sec;
    colortheme_txt1_Arr = ['rgba(255, 255, 255, 0.8)', 'black'];
    colortheme_txt1;
  selectedLanguage: any;
  uname:string;
  @ViewChild('langCheck', { static: false }) langCheck;
  @ViewChild('langSubmit',{static:false}) langSubmit
  timestamp: string;
  hashcode: any;
  langValue: any=localStorage.getItem('selectedlanguage');
  langaugename:{lang_id:number,original_name:string};
  flangValue:any;
  selectedLang:any;
 selectedValue:any
  constructor( private NgbModalConfig_ts: NgbModalConfig, private route:Router, private modalService: NgbModal, private PuzzlesqueryService_ts: PuzzlesqueryService,private SkillkitService_ts: SkillkitService, private fb:FormBuilder ,private ip: IpService, private translate :TranslateService) { 
   this.selectedValue= localStorage.getItem('selectedlanguage')
   this.uname=localStorage.getItem('uname')
   let browserLang
  //  if(localStorage.getItem('lang')){
  //   browserLang=localStorage.getItem('lang')
  //   }
  //debugger
  if(localStorage.getItem('selectedlanguage')){
    browserLang=localStorage.getItem('selectedlanguage')
    if(browserLang=='4'){
      browserLang='en'   //previously it was ar
    }
    else if(browserLang=='1'){
      browserLang='en'
    }
  }
    else{
    browserLang=translate.getBrowserLang()
    }
  //  browserLang= localStorage.getItem('lang')
    // this.selectedLang=localStorage.setItem('lang',browserLang)
    
    translate.use(browserLang.match(/en/) ? browserLang : 'en');

    if(browserLang=='ar'){
    // var element = document.getElementsByTagName("body")[0]
    // element.classList.add("rtl");
    }
  }

  ngOnInit() {
    this.colortheme_bg_base_sec = this.colortheme_base_sec[1];
    this.colortheme_txt1 = this.colortheme_txt1_Arr[1];
    this.colortheme_bg = this.colortheme[1];
    this.colortheme_txt = this.colortheme[0];
    // if (parseInt(localStorage.getItem("isdark")) == 0) {
    //   this.colortheme_txt = this.colortheme[1];
    // }
    // else {
    //   this.colortheme_txt = this.colortheme[0];
    // }

  if(this.route.url=='/login'){
      this.colortheme_bg_base_sec=this.colortheme_base_sec[2]
    }
    this.langModel()
  }

  // initForm(){
  //   this.language = this.fb.group(
  //     {
  //       lang: [null],
       
  //       //   confirmpassword: [null, [Validators.required, Validators.minLength(6), Validators.maxLength(20)]]
  //     })
  // }
  
langModel(){
  this.SkillkitService_ts.getLanguage().subscribe(res=>{
    console.log(res)
 
    this.selectedLanguage=res.result
   
    this.modalService.open(this.langCheck, { centered: true });
  })
}

languageSelected(){
 // debugger
  if(this.langValue==undefined){
    // this.ip.enableToaster('e', 'Need to Select any language')
    // return
    this.langValue=1
  }
  localStorage.setItem('selectedlanguage',this.langValue)
  console.log("submitted")
  let date = new Date();
let hash_value = this.ip.gethash(date);
this.timestamp = date.toString();
this.hashcode = hash_value;
let userid = localStorage.getItem("uid")
const params={
uid:userid,
timestamp:this.timestamp,
hashcode:this.hashcode,
langid:this.langValue,
langflag:1
}

this.SkillkitService_ts.updateLanguage(params).subscribe((res)=>{
  localStorage.setItem('language','1')
  console.log(res)
  let success=this.translate.instant('langauge-modal.success')
  this.ip.enableToaster('s', success)
},
err=>{
let error=this.translate.instant('language-modal.error')
this.ip.enableToaster('e', error)
}

)
this.ip.getPopup(false)
localStorage.setItem('lang',this.flangValue)
this.selectedLang=localStorage.getItem('lang')
if(this.selectedLang=='ar'){
//  var element = document.getElementsByTagName("body")[0]
//  element.classList.add("rtl");
 }
 else{
//    var element = document.getElementsByTagName("body")[0]
//  element.classList.remove("rtl");
 }
 this.selectedValue= localStorage.getItem('selectedlanguage')

 this.langaugename= this.selectedLanguage.find(langname=>{
  return langname.lang_id==this.selectedValue
})
console.log(this.langaugename)
this.translate.use(this.selectedLang)
this.modalService.open(this.langSubmit,{centered:true})
}

makeFalse(){
  //debugger
  this.ip.getPopup(false)
}

// ngxLangSelect(){
//   localStorage.setItem('lang',this.flangValue)
//    this.selectedLang=localStorage.getItem('lang')
//    if(this.selectedLang=='ar'){
//     var element = document.getElementsByTagName("body")[0]
//     element.classList.add("rtl");
//     }
//     else{
//       var element = document.getElementsByTagName("body")[0]
//     element.classList.remove("rtl");
//     }
   
//   this.translate.use(this.selectedLang)
// }

languageChange(value:any){
 //debugger
 this.langaugename= this.selectedLanguage.find(langname=>{
    return langname.lang_id==value
  })
  console.log(this.langaugename.original_name)
  if(value=='1'){
    this.flangValue='en'
  }
  else if(value=='4'){
    this.flangValue='ar'
  }
 this.langValue=value
console.log(value)
// this.ngxLangSelect()
// localStorage.setItem('lang',this.langValue)
//    this.selectedLang=localStorage.getItem('lang')
//    if(this.selectedLang=='ar'){
//     var element = document.getElementsByTagName("body")[0]
//     element.classList.add("rtl");
//     }
//     else{
//       var element = document.getElementsByTagName("body")[0]
//     element.classList.remove("rtl");
//     }
   
//   this.translate.use(this.selectedLang)


}

}
