import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: "root",
})
export class GlobalVariableService {
  issessioncompleted: boolean = false;
  isallattemptcompleted: boolean = false;
  isanygameincontinue: boolean = false;
  completedsessioncount: number = 0;
  isfirstloginoftheday: boolean = false;
  currentpage: string = "puzzle";
  constructor() {}
  private issessioncompletedsrc = new BehaviorSubject(this.issessioncompleted);
  private isallattemptcompletedsrc = new BehaviorSubject(
    this.isallattemptcompleted
  );
  private isanygameincontinuesrc = new BehaviorSubject(
    this.isanygameincontinue
  );
  private completedsessioncountsrc = new BehaviorSubject(
    this.completedsessioncount
  );
  private isfirstloginofthedaysrc = new BehaviorSubject(
    this.isfirstloginoftheday
  );
  private currentpagechangesrc = new BehaviorSubject(this.currentpage);
  currentissessioncompletedchange = this.issessioncompletedsrc.asObservable();
  changeissessioncompleted(issessioncompletedsrc: boolean) {
    this.issessioncompletedsrc.next(issessioncompletedsrc);
  }
  currentisallattemptcompletedchange =
    this.isallattemptcompletedsrc.asObservable();
  changeisallattemptcompleted(isallattemptcompletedsrc: boolean) {
    this.isallattemptcompletedsrc.next(isallattemptcompletedsrc);
  }
  currentisanygameincontinuechange = this.isanygameincontinuesrc.asObservable();
  changeisanygameincontinue(isanygameincontinuesrc: boolean) {
    this.isanygameincontinuesrc.next(isanygameincontinuesrc);
  }
  currentcompletedsessioncountchange =
    this.completedsessioncountsrc.asObservable();
  changecompletedsessioncount(completedsessioncountsrc: number) {
    this.completedsessioncountsrc.next(completedsessioncountsrc);
  }
  currentisfirstloginofthedaychange =
    this.isfirstloginofthedaysrc.asObservable();
  changeisfirstloginoftheday(isfirstloginofthedaysrc: boolean) {
    this.isfirstloginofthedaysrc.next(isfirstloginofthedaysrc);
  }
  currentpagechange = this.currentpagechangesrc.asObservable();
  changecurrentpagechange(currentpagechangesrc: string) {
    this.currentpagechangesrc.next(currentpagechangesrc);
  }
}
